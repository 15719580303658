<template>
  <div class="tables col-12 col-md-6 px-1 mx-auto row">
    <div class="col-12 p-0">
      <h4 class="card-title mb-1" style="color: #cacaca">
        Cadres d’entreprise
      </h4>
      <hr class="mt-0" style="background-color: #cacaca" />
      <b-table
        :items="tableItems"
        :fields="tableFields"
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy="loading"
        bordered
        striped
        show-empty
      >
        <!-- FOR LOADING ANIMATIONS -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Chargement...</strong>
          </div>
        </template>
        <!-- SHOW IF TABLE IS EMPTY -->
        <template #empty>
          <div class="text-center text-gray">Table vide</div>
        </template>

        <template #cell(name)="data">
          <b-link
            :to="{
              name: 'show-principe-ref',

              params: {
                id: data.item.id,
                slug: data.item.slug || 'abcde',
              },
            }"
            >{{ data.value }}</b-link
          >
        </template>
        <template #cell(type)="data">
          <span>{{ data.value || "-" }}</span>
        </template>
      </b-table>
    </div>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
      class="ml-auto mt-auto col-12 p-0"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data: () => ({
    sortBy: "id",
    perPage: 5,
    currentPage: 1,
    sortDesc: true,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    loading: true,
    tableItems: [],
    tableFields: [
      { key: "name", label: "Cadres", sortable: true },

      {
        key: "type",
        label: "Type",
        sortable: true,
      },
      {
        key: "createdAt",
        label: "Date validation",
        sortable: true,
      },
    ],
  }),
  created() {
    this.$store
      .dispatch("cadre/fetchAllCadres")
      .then(() => (this.loading = false));
  },
  computed: {
    ...mapGetters("cadre", ["CADRE_ENTREPRISES"]),

    rows() {
      return this.tableItems.length;
    },
  },
  watch: {
    CADRE_ENTREPRISES: function () {
      if (this.CADRE_ENTREPRISES.length)
        this.tableItems = this.CADRE_ENTREPRISES.map((cadre) => ({
          ...cadre,
          createdAt: moment(cadre.createdAt, "DD/MM/YYYY").format("DD/MM/YYYY"),
        }));
    },
  },
};
</script>
