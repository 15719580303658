<template>
  <div class="tables col-12 col-md-6 px-1 mx-auto row">
    <div class="col-12 p-0">
      <h4 class="card-title mb-1" style="color: #cacaca">
        Nouvelles applications
      </h4>
      <hr class="mt-0" style="background-color: #cacaca" />
      <b-table
        :items="tableItems"
        :fields="tableFields"
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy="loading"
        bordered
        striped
        show-empty
      >
        <!-- FOR LOADING ANIMATIONS -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Chargement...</strong>
          </div>
        </template>
        <!-- SHOW IF TABLE IS EMPTY -->
        <template #empty>
          <div class="text-center text-gray">Table vide</div>
        </template>

        <template v-slot:cell(user)="data">
          <div>
            <b-avatar
              v-b-tooltip.hover="
                data.value.firstname + ' ' + data.value.lastname
              "
              variant="primary"
              class="mr-1"
            >
              <div
                style="
                  width: 30px;
                  height: 30px;
                  border-radius: 15px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                {{ data.value.firstname[0] + "." + data.value.lastname[0] }}
              </div>
            </b-avatar>
          </div>
        </template>

        <template #cell(patrimoine)="data">
          <b-link
            v-if="data.value"
            :to="{
              name: 'show-patrimony',
              params: {
                id: data.value.id,
                slug: data.value.slug || 'abcde',
              },
            }"
            >{{ data.value.name }}</b-link
          >
          <span v-else>-</span>
        </template>

        <template #cell(validateDate)="data">
          <span>{{ data.value }}</span>
        </template>

        <template #cell(name)="data">
          <b-link
            :to="{
              name: 'show-application',
              params: {
                id: data.item.id,
                slug: data.item.slug,
              },
            }"
            >{{ data.value }}</b-link
          >
        </template>
      </b-table>
    </div>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
      class="ml-auto mt-auto col-12 p-0"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    sortBy: "id",
    perPage: 5,
    currentPage: 1,
    sortDesc: true,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    loading: true,
    tableItems: [],
    tableFields: [
      { key: "name", label: "Applications", sortable: true },

      {
        key: "patrimoine",
        label: "Patrimoine",
        sortable: true,
      },
      {
        key: "validateDate",
        label: "Date validation",
        sortable: true,
      },
    ],
  }),
  created() {
    this.$store
      .dispatch("application/fetchAllApplications")
      .then(() => (this.loading = false));
  },
  computed: {
    ...mapGetters("application", ["APPLICATIONS"]),

    rows() {
      return this.tableItems.length;
    },
  },
  watch: {
    APPLICATIONS: function () {
      if (this.APPLICATIONS.length) this.tableItems = this.APPLICATIONS;
    },
  },
};
</script>
