

export const firstSection = [
    [
        "Initiatives",
        "mdi-lightbulb",
        19,
        "rgb(47, 85, 151)",
        "/transformation/initiative",
    ],
    ["Livrables", "mdi-folder", 90, "rgb(47, 85, 151)",
        "/transformation/deliverable",],
    ["Objectifs Stratégiques", "mdi-target", 12, "rgb(47, 85, 151)",
        "/strategy/goals",],
    [
        "Feuilles de route",
        "mdi-map-legend",
        22,
        "rgb(47, 85, 151)",
        "/transformation/fdr",
    ],
];


export const secondSection = [
    [
        "Composants techniques",
        "mdi-hammer-screwdriver",
        70,
        "rgb(197, 90, 17)",
        "/reference/vue-technique",
    ],
    ["Capacités métier", "mdi-human", 12, "rgb(197, 90, 17)",

        "/reference/vue-metier"],
    ["Applications", "mdi-cellphone-link", 90, "rgb(197, 90, 17)",

        "/reference/vue-applicative"],
    ["Patterns", "mdi-notebook", 13, "rgb(197, 90, 17)",
        "/reference/principes"],
    ["Patrimoine", "mdi-domain", 33, "rgb(197, 90, 17)",
        "/transformation/patrimony"],
]
