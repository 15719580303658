<template>
  <div class="tables col-12 col-md-6 px-1 mx-auto row">
    <div class="col-12 p-0">
      <h4 class="card-title mb-1" style="color: #cacaca">
        Nouveaux collaborateurs
      </h4>
      <hr class="mt-0" style="background-color: #cacaca" />
      <b-table
        :items="tableItems"
        :fields="tableFields"
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy="loading"
        striped
        bordered
        show-empty
      >
        <!-- FOR LOADING ANIMATIONS -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Chargement...</strong>
          </div>
        </template>
        <!-- SHOW IF TABLE IS EMPTY -->
        <template #empty>
          <div class="text-center text-gray">Table vide</div>
        </template>

        

        <template v-slot:cell(user)="data">
          <b-link
            :to="{
              name: 'show-actor',
              params: {
                id: data.value.id,
                slug: data.value.slug || 'abcde',
              },
            }"
            style="text-decoration: none"
          >
            <TableResponsable :responsable="data.value"
          /></b-link>
        </template>

        <template #cell(team)="data">
          <b-link
            v-if="data.value"
            :to="{
              name: 'show-team',
              params: {
                id: data.value.id,
                slug: data.value.slug || 'abcde',
              },
            }"
          >
            {{ data.value.name }}
          </b-link>
          <span v-else>-</span>
        </template>
      </b-table>
    </div>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
      class="ml-auto mt-auto col-12 p-0"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TableResponsable from "@/components/custom/TableResponsable.vue";

export default {
  components: { TableResponsable },

  data: () => ({
    sortBy: "id",
    perPage: 5,
    currentPage: 1,
    sortDesc: true,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    loading: true,
    tableItems: [],
    tableFields: [
      { key: "user", label: "Nom", sortable: true },
      {
        key: "createdAt",
        label: "Date d'arrivée",
        sortable: true,
      },
      {
        key: "team",
        label: "Equipe",
        sortable: true,
      },
    ],
  }),
  created() {
    this.$store
      .dispatch("users/fetchAllUsers")
      .then(() => (this.loading = false));
  },
  computed: {
    ...mapGetters("users", ["USERS"]), //get all users

    rows() {
      return this.tableItems.length;
    },
  },
  watch: {
    USERS: function () {
      if (this.USERS.length) this.tableItems = this.USERS;
      this.tableItems = this.tableItems.map((user) => ({
        user: user,
        createdAt: "15/01/2022",
        team: user.team,
      }));
    },
  },
};
</script>
