<template>
  <div class="prog_cust d-flex align-items-center w-100">
    <div :style="{ width: `${value}%`, backgroundColor: color }" class="ins">
      <p class="txt">{{ value }} %</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressCustom",
  props: {
    color: String,
    value: Number,
  },
};
</script>

<style scoped>
.prog_cust {
  background-color: rgb(219, 219, 219);
  height: 18px;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
}
.txt {
  font-size: 12px;
  color: white;
}

.ins {
  position: absolute;
  height: 100%;
  padding: 0px 5px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  text-align: center;
}
</style>