<template>
  <div class="d-flex col-12 w-full gap-1">
    <CalendarDate
      v-for="(date, index) in week"
      :key="index"
      :date="date"
      :is-today="isToday === date"
      :is-selected="highlightedDates.indexOf(date) >= 0"
    />
  </div>
</template>

<script>
import CalendarDate from "./CalendarDate";
export default {
  components: {
    CalendarDate,
  },
  props: {
    week: {
      type: Array,
      default: () => [],
    },
    isToday: {
      type: Number,
      default: 0,
    },
    // isSelected: {
    //   type: Number,
    //   default: 0,
    // },
    highlightedDates: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
