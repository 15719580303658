<template>
  <div class="col-12 p-0">
    <div class="d-flex bg-secondary text-white pb-4 col-12 w-full gap-1">
      <CalendarWeekHeader v-for="(day, index) in daysInWeek" :key="index" :day="day" />
    </div>
    <div class="grid grid-cols-7 py-2 gap-1" style="min-height: 350px">
      <CalendarWeek
        :highlightedDates="highlightedDates"
        v-for="(week, index) in datesPerWeek"
        :key="index"
        :week="week"
        :is-today="isToday"
        :is-selected="isSelected"
      />
    </div>
  </div>
</template>

<script>
import CalendarWeekHeader from "./CalendarWeekHeader";
import CalendarWeek from "./CalendarWeek";

export default {
  components: {
    CalendarWeekHeader,
    CalendarWeek,
  },
  props: {
    datesPerWeek: {
      type: Array,
      default: () => [],
    },
    isToday: {
      type: Number,
      default: 0,
    },
    isSelected: {
      type: Number,
      default: 0,
    },
    weekStartDay: {
      type: Number,
      default: 0,
    },
    highlightedDates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // daysInWeek: ["S", "M", "T", "W", "T", "F", "S"],
      daysInWeek: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
    };
  },
  created() {
    this.rotateDaysInWeek(this.daysInWeek, this.weekStartDay);
  },
  methods: {
    rotateDaysInWeek(daysInWeek, shifts) {
      while (shifts--) {
        var temp = daysInWeek.shift();
        daysInWeek.push(temp);
      }
    },
  },
};
</script>
