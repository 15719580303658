<template>
  <div class="d-flex flex-wrap">
    <div class="col-sm-12 col-md-5 m-0 p-0 pr-md-2">
      <calendar-view
        @dateUpdated="updateDate"
        :highlightedDates="highlightedDates"
      />
    </div>

    <div class="card pt-4 pb-3 col-12 mt-2 mt-md-0 col-md-7">
      <div class="col-12">
        <div class="p-0 h-100 row">
          <div class="col-12 p-0">
            <h4 class="card-title mb-1">
              <span style="color: #cacaca"> Les comités de </span>
              <span style="color: #37d3bc !important">{{ currentDate }}</span>
            </h4>

            <hr class="mt-0 bg-secondary" />
            <div class="table-responsive m-0 p-0 d-block">
              <b-table
                bordered
                striped
                :items="tableItems"
                :fields="tableFields"
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :busy="loading"
                show-empty
              >
                <!-- FOR LOADING ANIMATIONS -->
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-2"></b-spinner>
                    <strong>Chargement...</strong>
                  </div>
                </template>
                <!-- SHOW IF TABLE IS EMPTY -->
                <template #empty>
                  <div class="text-center text-gray">Table vide</div>
                </template>

                <template #cell(name)="data">
                  <b-link
                    :to="{
                      name: 'show-session',
                      params: {
                        id: data.item.id,
                        slug: data.item.slug,
                      },
                    }"
                    >{{ data.item.planning.comite.name }}</b-link
                  >
                </template>
                <template #cell(statut)="data">
                  <span>{{ data.value }}</span>
                </template>
                <template #cell(createdAt)="data" class="w-100 text-center">
                  <b-link
                    :to="{
                      name: 'show-session',
                      params: {
                        id: data.item.id,
                        slug: data.item.slug,
                      },
                    }"
                    >{{
                      moment(data.item.date, "DD/MM/YYYY").format("DD/MM/YYYY")
                    }}</b-link
                  >
                </template>
              </b-table>
            </div>
          </div>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="table-list"
            align="right"
            class="ml-auto mt-auto col-12 p-0"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import DatePicker from "vue2-datepicker";

import { mapGetters } from "vuex";
import CalendarView from "./calendar/CalendarView";
import moment from "moment";
import { uniq } from "lodash";
import "moment/locale/fr";
export default {
  components: {
    //DatePicker,
    CalendarView,
  },
  data() {
    return {
      currentDate: "",
      sortBy: "createdAt",
      perPage: 5,
      month: "",
      year: "",
      months: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ],
      englishMonths: [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
      ],

      englishMonth: "",
      currentPage: 1,
      sortDesc: true,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      loading: true,
      highlightedDates: [],
      tableItems: [],
      tableFields: [
        { key: "name", label: "Nom du comité	", sortable: true },
        {
          key: "createdAt",
          label: "Date",
          sortable: true,
        },
        {
          key: "status",
          label: "Statut",
          sortable: true,
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("manage/fetchAllSessions").then(() => {
      this.loading = false;
    });
  },
  computed: {
    ...mapGetters("comite", ["COMITES"]),
    ...mapGetters("manage", ["SESSIONS"]),

    rows() {
      return this.tableItems.length;
    },
  },
  watch: {
    SESSIONS() {
      this.tableItems = this.SESSIONS.filter(
        (session) =>
          moment(session.date, "DD/MM/YYYY").format("MM/YYYY") ===
          moment(this.englishMonth).format("MM/YYYY")
      );

      this.highlightedDates = uniq(
        this.tableItems.map((session) =>
          parseInt(moment(session.date, "DD/MM/YYYY").format("DD"))
        )
      );
    },
    month: function () {
      this.tableItems = this.SESSIONS.filter(
        (session) =>
          moment(session.date, "DD/MM/YYYY").format("MM/YYYY") ===
          moment(this.englishMonth).format("MM/YYYY")
      );

      this.highlightedDates = uniq(
        this.tableItems.map((session) =>
          parseInt(moment(session.date, "DD/MM/YYYY").format("DD"))
        )
      );
    },
    year: function () {
      this.tableItems = this.SESSIONS.filter(
        (session) =>
          moment(session.date, "DD/MM/YYYY").format("MM/YYYY") ===
          moment(this.englishMonth).format("MM/YYYY")
      );
    },
    currentDate: function () {
      this.tableItems = this.SESSIONS.filter(
        (session) =>
          moment(session.date, "DD/MM/YYYY").format("MM/YYYY") ===
          moment(this.englishMonth).format("MM/YYYY")
      );
    },
  },
  mounted() {
    this.month = document.getElementById("month").textContent.trim();
    this.year = document.getElementById("year").textContent.trim();
    this.currentDate = this.month + " " + this.year;

    this.englishMonth =
      this.englishMonths[this.months.indexOf(this.month)] + " " + this.year;
  },
  updated() {
    this.month = document.getElementById("month").textContent.trim();
    this.year = document.getElementById("year").textContent.trim();
    this.currentDate = this.month + " " + this.year;
  },
  methods: {
    updateDate: function (month, year) {
      this.month = month + 1;
      this.currentDate = this.months[month] + " " + year;
      this.englishMonth = this.englishMonths[month] + " " + year;
    },
  },
};
</script>
