var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tables col-12 col-md-6 px-1 mx-auto row"},[_c('div',{staticClass:"col-12 p-0"},[_c('h4',{staticClass:"card-title mb-1",staticStyle:{"color":"#cacaca"}},[_vm._v(" Nouvelles applications ")]),_c('hr',{staticClass:"mt-0",staticStyle:{"background-color":"#cacaca"}}),_c('b-table',{attrs:{"items":_vm.tableItems,"fields":_vm.tableFields,"responsive":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"busy":_vm.loading,"bordered":"","striped":"","show-empty":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Chargement...")])],1)]},proxy:true},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"cell(user)",fn:function(data){return [_c('div',[_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
              data.value.firstname + ' ' + data.value.lastname
            ),expression:"\n              data.value.firstname + ' ' + data.value.lastname\n            ",modifiers:{"hover":true}}],staticClass:"mr-1",attrs:{"variant":"primary"}},[_c('div',{staticStyle:{"width":"30px","height":"30px","border-radius":"15px","display":"flex","align-items":"center","justify-content":"center"}},[_vm._v(" "+_vm._s(data.value.firstname[0] + "." + data.value.lastname[0])+" ")])])],1)]}},{key:"cell(patrimoine)",fn:function(data){return [(data.value)?_c('b-link',{attrs:{"to":{
            name: 'show-patrimony',
            params: {
              id: data.value.id,
              slug: data.value.slug || 'abcde',
            },
          }}},[_vm._v(_vm._s(data.value.name))]):_c('span',[_vm._v("-")])]}},{key:"cell(validateDate)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.value))])]}},{key:"cell(name)",fn:function(data){return [_c('b-link',{attrs:{"to":{
            name: 'show-application',
            params: {
              id: data.item.id,
              slug: data.item.slug,
            },
          }}},[_vm._v(_vm._s(data.value))])]}}])})],1),_c('b-pagination',{staticClass:"ml-auto mt-auto col-12 p-0",attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"table-list","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }