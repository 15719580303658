<template>
  <div class="tables col-12 col-md-6 px-1 mx-auto row">
    <div class="col-12 p-0">
      <h4 class="card-title mb-1" style="color: #cacaca">
        Nouvelles initiatives
      </h4>
      <hr class="mt-0" style="background-color: #cacaca" />
      <b-table
        :items="tableItems"
        :fields="tableFields"
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy="loading"
        striped
        bordered
        show-empty
        id="initiative-table"
      >
        <!-- FOR LOADING ANIMATIONS -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Chargement...</strong>
          </div>
        </template>
        <!-- SHOW IF TABLE IS EMPTY -->
        <template #empty>
          <div class="text-center text-gray">Table vide</div>
        </template>

        <template #cell(startedAt)="data">
          <span
            style="display: block !important; text-align: center !important"
            >{{ data.value }}</span
          >
        </template>

        <template #cell(patrimoine)="data">
          <b-link
            v-if="data.item.feuilleRoute && data.item.feuilleRoute.patrimoine"
            :to="{
              name: 'show-patrimony',
              params: {
                id: data.item.feuilleRoute.patrimoine.id,
                slug: data.item.feuilleRoute.patrimoine.slug || 'abcde',
              },
            }"
            >{{ data.item.feuilleRoute.patrimoine.name }}</b-link
          >
          <span v-else>-</span>
        </template>
        <template #cell(libelle)="data">
          <b-link
            :to="{
              name: 'show-initiative',
              params: {
                id: data.item.id,
                slug: data.item.slug,
              },
            }"
            >{{ data.value }}</b-link
          >
        </template>
      </b-table>
    </div>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
      class="ml-auto mt-auto col-12 p-0"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    sortBy: "id",
    perPage: 5,
    currentPage: 1,
    sortDesc: true,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    loading: true,
    tableItems: [],
    tableFields: [
      {
        key: "libelle",
        label: "Initiatives",
        sortable: true,
        tdStyle: { height: "70.5px", paddingLeft: "0 !important" },
      },
      {
        key: "startedAt",
        label: "Date de début",
        sortable: true,
        tdStyle: { height: "70.5px" },
      },
      {
        key: "patrimoine",
        label: "Patrimoine",
        sortable: true,
        tdStyle: { height: "70.5px" },
      },
    ],
  }),
  created() {
    this.$store
      .dispatch("initiative/fetchAllInitiatives")
      .then(() => (this.loading = false));
  },
  computed: {
    ...mapGetters("initiative", ["INITIATIVES"]),

    rows() {
      return this.tableItems.length;
    },
  },
  methods: {},
  watch: {
    INITIATIVES: function () {
      if (this.INITIATIVES.length) this.tableItems = this.INITIATIVES;
    },
  },
};
</script>
<style>
#initiative-table td {
  height: 71px !important;
}
</style>
