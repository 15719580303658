var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"dashboard"},[(_vm.isManager || _vm.isArchitect || _vm.isPo || _vm.isAdmin || _vm.isSuper)?_c('div',[_c('h4',{staticClass:"mt-4 text-white mb-1 bg-secondary rounded d-flex py-1 px-2",staticStyle:{"width":"260px"}},[_vm._v(" Portefeuilles transformation ")]),_c('hr',{staticClass:"mt-0 mb-4 bg-secondary"}),_c('div',{staticClass:"row col-sm-12 grid-margin p-0 m-0"},_vm._l((_vm.firstSection),function(item,index){return _c('div',{key:index + ' frst-sect',class:{
          'col-sm-12 col-md-6 col-lg m-0 p-0 mt-3 ': true,
          ' pl-md-2 ': index > 0,
        },staticStyle:{"cursor":"pointer"}},[_c('router-link',{style:({ color: item[3] }),attrs:{"to":index === 0 && _vm.isManager
              ? ''
              : `${item.length > 4 ? item[4] : ''}`,"role":"button"},nativeOn:{"click":function($event){return _vm.showAllert(
              index === 0
                ? _vm.progresses['initiatives'].percentage
                : index === 1
                ? _vm.progresses['livrables'].percentage
                : index === 2
                ? _vm.progresses['objectifs'].percentage
                : index === 3
                ? _vm.progresses['fdr'].percentage
                : 0,
              index
            )}}},[_c('div',{staticClass:"card card-statistics"},[_c('div',{staticClass:"card-body",staticStyle:{"min-height":"180px"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-between",staticStyle:{"min-height":"70px"}},[_c('div',[_c('i',{class:`mdi icon-lg ${item[1]}`,style:({ color: item[3] })})]),_c('div',[_c('p',{staticClass:"mb-0 text-right txt"},[_vm._v(" "+_vm._s(item[0])+" ")]),_c('div',{staticClass:"fluid-container"},[_c('h3',{staticClass:"font-weight-medium text-right mb-0"},[_vm._v(" "+_vm._s(_vm.portefeuils ? _vm.portefeuils.transformation[index] : 0)+" ")])])])]),_c('Progress',{staticStyle:{"margin-top":"10px"},attrs:{"color":item[3],"value":parseFloat(
                    Number.parseFloat(
                      index === 0
                        ? _vm.progresses['initiatives'].percentage
                        : index === 1
                        ? _vm.progresses['livrables'].percentage
                        : index === 2
                        ? _vm.progresses['objectifs'].percentage
                        : index === 3
                        ? _vm.progresses['fdr'].percentage
                        : 0
                    ).toFixed(2)
                  )}})],1)])])],1)}),0),_c('h4',{staticClass:"mt-4 text-white mb-1 rounded d-flex py-1 px-2",staticStyle:{"width":"260px","background-color":"rgb(197, 90, 17)"}},[_vm._v(" Portefeuilles de patrimoine ")]),_c('hr',{staticClass:"mt-0 mb-4 bg-secondary"}),_c('div',{staticClass:"row col-sm-12 m-0 p-0 grid-margin"},_vm._l((_vm.secondSection),function(item,index){return _c('div',{key:index + ' scnd-sect',class:{
          'col-sm-12 col-md-6 col-lg m-0 p-0 mt-3 ': true,
          ' pl-md-2 ': index > 0,
        },staticStyle:{"cursor":"pointer"},style:({ color: item[3] })},[_c('router-link',{staticClass:"card card-statistics",style:({ color: item[3] }),attrs:{"to":item[4]},nativeOn:{"click":function($event){index == 0
              ? _vm.$store.dispatch('tabs/changeVueTechniqueTab', 1)
              : index == 2
              ? _vm.$store.dispatch('tabs/changeVueApplicativeTab', 1)
              : index == 3
              ? _vm.$store.dispatch('tabs/changeCadreTab', 2)
              : ''}}},[_c('div',{staticClass:"card-body",staticStyle:{"min-height":"180px"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-between",staticStyle:{"min-height":"70px"}},[_c('div',{},[_c('i',{class:`mdi icon-lg ${item[1]}`,style:({ color: item[3] })})]),_c('div',[_c('p',{staticClass:"mb-0 text-right txt"},[_vm._v(_vm._s(item[0]))]),_c('div',{staticClass:"fluid-container"},[_c('h3',{staticClass:"font-weight-medium text-right mb-0"},[_vm._v(" "+_vm._s(_vm.portefeuils ? _vm.portefeuils.patrimoine[index] : 0)+" ")])])])]),_c('Progress',{staticStyle:{"margin-top":"10px"},attrs:{"color":item[3],"value":parseFloat(
                  Number.parseFloat(
                    index === 0
                      ? _vm.progresses['composants'].percentage
                      : index === 1
                      ? _vm.progresses['capacites'].percentage
                      : index === 2
                      ? _vm.progresses['applications'].percentage
                      : index === 3
                      ? _vm.progresses['patterns'].percentage
                      : index === 4
                      ? _vm.progresses['patrimoines'].percentage
                      : 0
                  ).toFixed(2)
                )}})],1)])],1)}),0)]):_vm._e(),(_vm.isGouvernance || _vm.isAdmin || _vm.isSuper || _vm.isFinance)?_c('div',[_c('h4',{staticClass:"mt-4 text-white mb-1 rounded d-flex py-1 px-2",staticStyle:{"width":"260px","background-color":"#37d3bc"}},[_vm._v(" Comités de gouvernance ")]),_c('hr',{staticClass:"mt-0 mb-4 bg-secondary"}),_c('Comite')],1):_vm._e(),_c('h4',{staticClass:"mt-4 text-white mb-1 rounded d-flex py-1 px-2",staticStyle:{"width":"260px","background-color":"#37d3bc"}},[_vm._v(" News et infos ")]),_c('hr',{staticClass:"mt-0 mb-4 bg-secondary"}),_c('div',{staticClass:"card grid-margin stretch-card"},[_c('div',{staticClass:"card-body row"},[_c('NewCollaborators'),_c('NewInitiatives'),_c('NewApplications'),_c('NewCadres')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }