var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tables col-12 col-md-6 px-1 mx-auto row"},[_c('div',{staticClass:"col-12 p-0"},[_c('h4',{staticClass:"card-title mb-1",staticStyle:{"color":"#cacaca"}},[_vm._v(" Cadres d’entreprise ")]),_c('hr',{staticClass:"mt-0",staticStyle:{"background-color":"#cacaca"}}),_c('b-table',{attrs:{"items":_vm.tableItems,"fields":_vm.tableFields,"responsive":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"busy":_vm.loading,"bordered":"","striped":"","show-empty":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Chargement...")])],1)]},proxy:true},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"cell(name)",fn:function(data){return [_c('b-link',{attrs:{"to":{
            name: 'show-principe-ref',

            params: {
              id: data.item.id,
              slug: data.item.slug || 'abcde',
            },
          }}},[_vm._v(_vm._s(data.value))])]}},{key:"cell(type)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.value || "-"))])]}}])})],1),_c('b-pagination',{staticClass:"ml-auto mt-auto col-12 p-0",attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"table-list","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }