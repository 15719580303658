<template>
  <div
    class="bg-gradient-to-r bg-secondary text-white h2 from-pink-100 to-pink-300 text-pink-600 items-center justify-between m-0 p-4 d-flex"
  >
    <div class="cursor-pointer" @click="toggleMonth(-1)">
      <i class="fa fa-angle-left" aria-hidden="true"></i>
    </div>
    <div class="font-bold text-2xl mx-auto capitalize">
      <span id="month">{{ fullMonth }}</span
      ><span id="year"> {{ year }}</span>
    </div>
    <div class="cursor-pointer" @click="toggleMonth(1)">
      <i class="fa fa-angle-right" aria-hidden="true"></i>
    </div>
  </div>
</template>

<script>
const MONTHS = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];
export default {
  props: {
    year: {
      type: Number,
      default: () => 1970,
    },
    month: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
    fullMonth() {
      return MONTHS[this.month];
    },
  },
  methods: {
    toggleMonth(direction) {
      this.$emit("toggleMonth", direction);
    },
  },
};
</script>
