<template>
  <td
    class="d-flex m-auto"
    :class="{
      ' rounded-pill text-white': isSelected && date,
    }"
    style="width: 14.28%; aspect-ratio: 1/1"
  >
    <div
      v-show="date"
      class="d-flex m-auto border-box rounded-pill h-7 w-7 align-middle cell"
      pill
      :class="{
        'text-white': date === 0,
        ' rounded-pill bg-success ': isSelected && date,
      }"
      style="height: 50px; aspect-ratio: 1/1"
    >
      <span v-if="date" class="m-auto"> {{ date }}</span>
    </div>
  </td>
</template>

<script>
export default {
  props: {
    date: {
      type: Number,
      default: () => 1,
    },
    isToday: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
