<template>
  <section class="dashboard">
    <div v-if="isManager || isArchitect || isPo || isAdmin || isSuper">
      <h4
        class="mt-4 text-white mb-1 bg-secondary rounded d-flex py-1 px-2"
        style="width: 260px"
      >
        Portefeuilles transformation
      </h4>

      <hr class="mt-0 mb-4 bg-secondary" />
      <div class="row col-sm-12 grid-margin p-0 m-0">
        <div
          v-for="(item, index) in firstSection"
          style="cursor: pointer"
          :key="index + ' frst-sect'"
          :class="{
            'col-sm-12 col-md-6 col-lg m-0 p-0 mt-3 ': true,
            ' pl-md-2 ': index > 0,
          }"
        >
          <router-link
            :style="{ color: item[3] }"
            :to="
              index === 0 && isManager
                ? ''
                : `${item.length > 4 ? item[4] : ''}`
            "
            role="button"
            @click.native="
              showAllert(
                index === 0
                  ? progresses['initiatives'].percentage
                  : index === 1
                  ? progresses['livrables'].percentage
                  : index === 2
                  ? progresses['objectifs'].percentage
                  : index === 3
                  ? progresses['fdr'].percentage
                  : 0,
                index
              )
            "
          >
            <div class="card card-statistics">
              <div class="card-body" style="min-height: 180px">
                <div
                  class="d-flex justify-content-between align-items-between"
                  style="min-height: 70px"
                >
                  <div>
                    <i
                      :style="{ color: item[3] }"
                      :class="`mdi icon-lg ${item[1]}`"
                    ></i>
                  </div>
                  <div>
                    <p class="mb-0 text-right txt">
                      {{ item[0] }}
                    </p>
                    <div class="fluid-container">
                      <h3 class="font-weight-medium text-right mb-0">
                        {{
                          portefeuils ? portefeuils.transformation[index] : 0
                        }}
                      </h3>
                    </div>
                  </div>
                </div>
                <Progress
                  style="margin-top: 10px"
                  :color="item[3]"
                  :value="
                    parseFloat(
                      Number.parseFloat(
                        index === 0
                          ? progresses['initiatives'].percentage
                          : index === 1
                          ? progresses['livrables'].percentage
                          : index === 2
                          ? progresses['objectifs'].percentage
                          : index === 3
                          ? progresses['fdr'].percentage
                          : 0
                      ).toFixed(2)
                    )
                  "
                />
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <h4
        class="mt-4 text-white mb-1 rounded d-flex py-1 px-2"
        style="width: 260px; background-color: rgb(197, 90, 17)"
      >
        Portefeuilles de patrimoine
      </h4>
      <hr class="mt-0 mb-4 bg-secondary" />
      <!-- {{ progresses }} -->

      <div class="row col-sm-12 m-0 p-0 grid-margin">
        <div
          v-for="(item, index) in secondSection"
          style="cursor: pointer"
          :style="{ color: item[3] }"
          :key="index + ' scnd-sect'"
          :class="{
            'col-sm-12 col-md-6 col-lg m-0 p-0 mt-3 ': true,
            ' pl-md-2 ': index > 0,
          }"
        >
          <router-link
            :to="item[4]"
            @click.native="
              index == 0
                ? $store.dispatch('tabs/changeVueTechniqueTab', 1)
                : index == 2
                ? $store.dispatch('tabs/changeVueApplicativeTab', 1)
                : index == 3
                ? $store.dispatch('tabs/changeCadreTab', 2)
                : ''
            "
            :style="{ color: item[3] }"
            class="card card-statistics"
          >
            <div class="card-body" style="min-height: 180px">
              <div
                class="d-flex justify-content-between align-items-between"
                style="min-height: 70px"
              >
                <div class="">
                  <i
                    :style="{ color: item[3] }"
                    :class="`mdi icon-lg ${item[1]}`"
                  ></i>
                </div>
                <div>
                  <p class="mb-0 text-right txt">{{ item[0] }}</p>
                  <div class="fluid-container">
                    <h3 class="font-weight-medium text-right mb-0">
                      <!--                    {{ item[2] }}-->
                      {{ portefeuils ? portefeuils.patrimoine[index] : 0 }}
                    </h3>
                  </div>
                </div>
              </div>
              <Progress
                style="margin-top: 10px"
                :color="item[3]"
                :value="
                  parseFloat(
                    Number.parseFloat(
                      index === 0
                        ? progresses['composants'].percentage
                        : index === 1
                        ? progresses['capacites'].percentage
                        : index === 2
                        ? progresses['applications'].percentage
                        : index === 3
                        ? progresses['patterns'].percentage
                        : index === 4
                        ? progresses['patrimoines'].percentage
                        : 0
                    ).toFixed(2)
                  )
                "
              />
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div v-if="isGouvernance || isAdmin || isSuper || isFinance">
      <h4
        class="mt-4 text-white mb-1 rounded d-flex py-1 px-2"
        style="width: 260px; background-color: #37d3bc"
      >
        Comités de gouvernance
      </h4>
      <hr class="mt-0 mb-4 bg-secondary" />
      <Comite />
    </div>

    <h4
      class="mt-4 text-white mb-1 rounded d-flex py-1 px-2"
      style="width: 260px; background-color: #37d3bc"
    >
      News et infos
    </h4>
    <hr class="mt-0 mb-4 bg-secondary" />
    <div class="card grid-margin stretch-card">
      <div class="card-body row">
        <NewCollaborators />
        <NewInitiatives />
        <NewApplications />
        <NewCadres />
      </div>
    </div>
  </section>
</template>

<script>
import Progress from "@/components/custom/Progress.vue";
import NewCollaborators from "./components/NewCollaborators.vue";
import NewInitiatives from "./components/NewInitiatives.vue";
import NewApplications from "./components/NewApplications.vue";
import NewCadres from "./components/NewCadres.vue";
import Comite from "./components/Comite.vue";
import { tableItems, firstSection, secondSection } from "./constants";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  name: "dashboard",
  components: {
    Progress,
    NewCollaborators,
    NewInitiatives,
    NewApplications,
    NewCadres,
    Comite,
  },
  data() {
    return {
      firstSection: firstSection,
      secondSection: secondSection,
      newCollaborators: {
        tableItems,
      },
    };
  },
  //   beforeCreate() {
  //   this.$store.dispatch("fetchUser").then(() => {
  //     // if (this.AuthenticatedUser.id)
  //       // this.$store.dispatch("loader/toggleLoading", false);
  //   });
  //   handleRoutesRedirect(this.$route, this.$router, this.$store.getters);
  // },
  computed: {
    ...mapGetters("dashboard", ["PORTEFUEILS", "PROGRESSES"]),
    ...mapGetters("initiative", ["INITIATIVES"]),
    ...mapGetters([
      "isManager",
      "isArchitect",
      "isPo",
      "isAdmin",
      "isSuper",
      "isGouvernance",
      "isFinance",
    ]),

    portefeuils() {
      return this.PORTEFUEILS;
    },
    progresses() {
      return this.PROGRESSES;
      // return this.PROGRESSES.map(( item ) => {
      //   return item.transformation.map(( item ) => {
      //     return item;
      //   });
      // });
    },
  },
  methods: {
    showAllert(perss, index) {
      window.console.log(perss);
      if (index === 0 && this.isManager) {
        // if (!perss)
        Swal.fire({
          title: "Oops...",
          text: "Vous n'avez pas accès à ce module!",
          type: "error",
        });
      }
    },
  },
  mounted: function () {
    // if (this.PORTEFUEILS) this.portefeuils = this.PORTEFUEILS;
  },
  created() {
    this.$store.dispatch("dashboard/fetchDashboardData");
    this.$store.dispatch("dashboard/fetchInitiativeProgress");
    this.$store.dispatch("dashboard/fetchFdrProgress");
    this.$store.dispatch("dashboard/fetchLivrableProgress");
    this.$store.dispatch("dashboard/fetchObjectifsProgress");
    this.$store.dispatch("dashboard/fetchApplicationsProgress");
    this.$store.dispatch("dashboard/fetchComposantsProgress");
    this.$store.dispatch("dashboard/fetchCapacitesProgress");
    this.$store.dispatch("dashboard/fetchPatrimoinesProgress");
    this.$store.dispatch("dashboard/fetchPatternsProgress");

    this.$store.dispatch("initiative/fetchAllInitiatives");
  },
};
</script>

<style scoped>
.icon-lg {
  font-size: 2.2rem !important;
}

.txt {
  font-size: 0.9rem !important;
}
</style>
<style>
.prog_cust .txt {
  min-width: 45px !important;
}
</style>
